import React from 'react';
import Helmet from 'react-helmet';

import Section from '$ui/section';
import Heading from '$ui/heading';

const RefundPolicyPage = () => (
  <Section className='pt-32'>
    <Helmet>
      <title>TheCRO &mdash; Refund Policy</title>
    </Helmet>

    <Heading>Refund Policy</Heading>

    <article className='mt-10 space-y-10 leading-relaxed'>
      <p>
        After access is granted, due to the sensitive, proprietary nature of the
        copyrighted content, CRO will not refund the membership fees for any
        reason.
      </p>

      <p>
        Your membership will be valid for one year and you will be liable to all
        the responsibilities and obligations of the CRO. However if you wish to
        continue working with CRO for the second year or more then you will have
        to renew your existing membership.
      </p>

      <p>No cash will be accepted in CRO.</p>
    </article>
  </Section>
);

export default RefundPolicyPage;
